<template>
  <div class="goal card d-flex flex-row p-2">
    <GoalIcon :goal="goal" />
    <div class="ml-3 py-2">
      <h6 class="font-weight-bold text-dark mb-1">{{ goal.title }}</h6>
      <p class="mb-3 text-small">Objectif à atteindre sous {{ goal.duration }}</p>
      <p class="mb-0 text-small" v-if="goal.endDate && goal.endDate !== ''">Fini {{ endDateFormatted }}</p>
    </div>
    <CDropdown
      :caret="false"
      class="menu-dots"
      :class="{disabled: isRequestOngoing}"
    >
      <template v-slot:toggler-content>
        <CIcon name="menuDots" height="24" width="24" class="bg-white" v-if="!isRequestOngoing"/>
        <span v-if="isRequestOngoing"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true">
        </span>
      </template>
      <CDropdownItem class="py-3 px-4" @click="removeGoal()">
        <CIcon name="cancel" height="24" width="24" class="mr-3"/>
        <span class="text-dark">Annuler mon objectif</span>
      </CDropdownItem>
      <CDropdownItem class="py-3 px-4"
                     @click="$router.push({name: 'GoalForm', params: {goal: goal, edit: true}})"
      >
        <CIcon name="edit" height="24" width="24" class="mr-3"/>
        <span class="text-dark">Modifier mon objectif</span>
      </CDropdownItem>
      <CDropdownItem class="py-3 px-4" v-if="goal.status !== 'done'" @click="completeGoal()">
        <CIcon name="smile" height="24" width="24" class="mr-3"/>
        <span class="text-dark">Indiquer comme terminé</span>
      </CDropdownItem>
    </CDropdown>
  </div>
</template>

<script>
import moment from 'moment';
import GoalIcon from '@/components/UIComponents/GoalIcon.vue';

export default {
  name: 'GoalCard',
  components: {
    GoalIcon
  },
  props: {
    goal: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isRequestOngoing: false
    };
  },
  methods: {
    removeGoal() {
      this.isRequestOngoing = true;
      this.$http.delete(`/api/goal/${this.goal.id}`)
        .then(response => {
          if (response.status === 200) {
            this.$emit('changed');
          }
        })
        .catch(err => {
          this.isRequestOngoing = false;
          this.$notify({
            title: 'Impossible de supprimer cet objectif.',
            type: 'warning',
          });
        });
    },
    completeGoal() {
      this.isRequestOngoing = true;
      this.$http.get(`/api/goal/finish/${this.goal.id}`)
        .then(response => {
          if (response.status === 200) {
            this.$emit('changed');
          }
          this.isRequestOngoing = false;
        })
        .catch(err => {
          this.isRequestOngoing = false;
          this.$notify({
            title: 'Impossible de completer cet objectif manuellement.',
            type: 'warning',
          });
        });
    },
  },
  computed: {
    endDateFormatted() {
      return moment(this.goal.endDate).format('dddd DD MMMM YYYY');
    }
  }
};
</script>

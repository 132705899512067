<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'profile',
  data() {
    return {
    };
  },
  computed: {
  }
};
</script>

<template>
  <form class="px-1" :style="$mq !== 'xl' && $mq !== 'lg' && !showMore ? { height: '232px' } : {}">
    <div
      class="form-group text-dark row mb-2 align-items-center"
      :class="$mq === 'xl' || $mq === 'lg' ? '' : 'border-white py-2'"
      v-for="rating in ratings"
      :key="rating.id"
    >
      <label class="col-9 col-xl-4 pl-0 text-left">
        {{ rating.name }}
        <sup>
          <CIcon
            name="info"
            height="12"
            width="12"
            v-tooltip:bottom.html="getTooltipHtml(rating)"
          />
        </sup>
      </label>
      <div class="col-3 col-xl-2 order-xl-10 text-right pr-0">{{ rating.value }}/10</div>
      <input
        type="range"
        class="custom-range col-12 col-xl-6 px-0"
        min="0"
        max="10"
        v-model="rating.value"
        @change="rating.default = false"
        :class="!rating.default ? 'after-drag' : ''"
      />
    </div>
    <div class="text-center mt-3" :class="$mq === 'xl' || $mq === 'lg' ? '' : 'row'">
      <button
        type="button"
        class="btn btn-primary"
        data-track-category="self-evaluation"
        data-track-action="self-evaluation-completed"
        :class="$mq === 'xl' || $mq === 'lg' ? '' : 'w-100 py-3 h-auto'"
        @click="sendDailyRating"
      >
        {{ $mq === 'xl' || $mq === 'lg' ? 'Confirmer et enregistrer' : 'Je valide !' }}
      </button>
    </div>
    <div
      class="more text-center p-3 border-top border-white bg-beige-light"
      v-if="$mq !== 'xl' && $mq !== 'lg' && !showMore"
      @click="showMore = true"
    >
      Voir +
    </div>
    <CModal :show="popup" :centered="true" size="sm" @update:show="closeSuccessPopup">
      Vos résultats ont été enregistrés
      <template #header>
        <h5 class="modal-title">Toutes nos félicitations !</h5>
        <CButton @click="closeSuccessPopup()" class="close">
          <CIcon :src="closeIcon" />
        </CButton>
      </template>
    </CModal>
  </form>
</template>

<script>
import moment from 'moment';
import closeIcon from '../../assets/icons/Close.svg';

export default {
  name: 'Ratings',
  data() {
    return {
      closeIcon,
      ratings: [
        {
          id: 1,
          name: 'Manque de sommeil',
          description:
            'J’ai des difficultés de sommeil, par exemple des problèmes d’endormissement, des réveils nocturnes fréquents ou des réveils très matinaux sans possibilité de rendormissement',
          value: 5,
          default: true,
          fieldName: 'sleep',
        },
        {
          id: 2,
          name: 'Difficultés relationnelles',
          description:
            "J'ai des difficultés dans ma relation aux autres et/ou des difficultés d'intégration",
          value: 5,
          default: true,
          fieldName: 'socialLinks',
        },
        {
          id: 3,
          name: 'Manque d’énergie',
          description:
            'Je ne me suis jamais senti(e) véritablement reposé(e), j’ai manqué d’énergie, je me suis senti(e) fatigué(e)',
          value: 5,
          default: true,
          fieldName: 'energy',
        },
        {
          id: 4,
          name: 'Anxiété, niveau de stress',
          description: 'Je me suis senti(e) inquiet(ète) et/ou anxieux(euse)',
          value: 5,
          default: true,
          fieldName: 'anxiety',
        },
        {
          id: 5,
          name: 'Baisse de moral',
          description: 'Je me suis senti(e) déprimé(e)',
          value: 5,
          default: true,
          fieldName: 'mood',
        },
        {
          id: 6,
          name: 'Mauvaise Image corporelle',
          description: 'Je n’aime pas mon corps ou certaines parties de mon corps',
          value: 5,
          default: true,
          fieldName: 'selfBodyImage',
        },
        {
          id: 7,
          name: 'Manque d’estime de soi',
          description: ' J’ai une mauvaise estime de moi-même ou un problème de confiance en moi',
          value: 5,
          default: true,
          fieldName: 'selfConfidence',
        },
        {
          id: 8,
          name: 'Mauvaise capacité de travail',
          description:
            'J’ai des difficultés dans mes activités professionnelles ou dans mes études ou dans la réalisation des tâches quotidiennes',
          value: 5,
          default: true,
          fieldName: 'work',
        },
        {
          id: 9,
          name: 'Douleurs articulaires',
          description: 'Mes articulations me font souffrir.',
          value: 5,
          default: true,
          fieldName: 'movementPain',
        },
      ],
      popup: false,
      showMore: false,
    };
  },
  methods: {
    closeSuccessPopup() {
      this.popup = !this.popup;

      if (this.successPopupTimeoutId) {
        clearTimeout(this.successPopupTimeoutId);
        this.successPopupTimeoutId = null;
      }
    },
    getTooltipHtml(rating) {
      return `<div class="h5 font-weight-bold">${rating.name}</div><p class="mb-0">${rating.description}</p>`;
    },
    async sendDailyRating() {
      const { user } = this.$store.state;

      if (!this.ratings || !user) {
        return;
      }

      const body = {
        userId: user.id,
        date: moment().format('YYYY-MM-DD'),
      };

      this.ratings.forEach(item => {
        if (item && item.fieldName) {
          body[item.fieldName] = item.value || 0;
        }
      });

      this.$http
        .post('/api/indicator', body)
        .then(() => {
          if ($('#reportFormModal').hasClass('show')) {
            $('#reportFormModal').modal('hide');
          } else {
            this.popup = true;
            this.successPopupTimeoutId = setTimeout(() => this.closeSuccessPopup(), 5000);

            this.ratings.forEach(rating => {
              rating.value = 5;
              rating.default = true;
            });
          }
          this.$emit('onDataSaved');
        })
        .catch(this.apiErrorCallback);
    },
  },
};
</script>

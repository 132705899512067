<template>
  <div>
    <form class="radar-chart-form d-flex justify-content-between mb-3 flex-wrap">
      <div class="input-group datepicker-input-group bg-beige-light mb-2">
        <input
          type="text"
          class="form-control datepicker-input datepicker-input--start"
          :id="startDateId"
          placeholder="JJ/MM/AAAA"
        />
        <div class="datepicker-icon">
          <CIcon name="calendar" class="input-group-append" size="xl" />
        </div>
      </div>
      <div class="input-group datepicker-input-group bg-beige-light mb-2">
        <input
          type="text"
          class="form-control datepicker-input datepicker-input--end"
          :id="endDateId"
          placeholder="JJ/MM/AAAA"
        />
        <div class="datepicker-icon">
          <CIcon name="calendar" class="input-group-append" size="xl" />
        </div>
      </div>
    </form>
    <CChartRadar
      class="radar-chart c-chart-radar"
      :datasets="defaultDatasets"
      :options="defaultOptions"
      :startDate="startDate"
      :endDate="endDate"
    />
  </div>
</template>

<script>
import { CChartRadar } from '@coreui/vue-chartjs';
import moment from 'moment';
import 'bootstrap-datepicker';
import { getStyle } from '@coreui/utils/src';

export default {
  name: 'RadarChart',
  components: { CChartRadar },
  props: {
    userId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  computed: {
    defaultDatasets() {
      const toReturn = [];

      if (this.startDatePoints && this.startDatePoints.length) {
        toReturn.push({
          label: 'Début',
          backgroundColor: getStyle('--secondary-pink-transparent'),
          borderColor: getStyle('--secondary-pink'),
          pointBackgroundColor: getStyle('--secondary-pink'),
          pointBorderColor: getStyle('--secondary-pink'),
          pointHoverBackgroundColor: getStyle('--secondary-pink'),
          pointHoverBorderColor: getStyle('--secondary-pink'),
          pointRadius: 4,
          borderWidth: 2,
          data: this.startDatePoints,
        });
      }

      if (this.endDatePoints && this.endDatePoints.length) {
        toReturn.push({
          label: 'Fin',
          backgroundColor: getStyle('--primary-transparent'),
          borderColor: getStyle('--primary'),
          pointBackgroundColor: getStyle('--primary'),
          pointBorderColor: getStyle('--primary'),
          pointHoverBackgroundColor: getStyle('--primary'),
          pointHoverBorderColor: getStyle('--primary'),
          pointRadius: 4,
          borderWidth: 2,
          data: this.endDatePoints,
        });
      }

      return toReturn;
    },
    defaultOptions() {
      return {
        aspectRatio: 1,
        legend: { display: false },
        layout: { padding: 10 },
        tooltips: { enabled: false },
        scale: {
          gridLines: {
            circular: true,
            color: getStyle('--white'),
          },
          angleLines: { display: false },
          ticks: {
            display: true,
            beginAtZero: true,
            showLabelBackdrop: false,
            stepSize: 1,
            max: 10,
            fontColor: getStyle('--white'),
            fontFamily: getStyle('--font-family-sans-serif'),
            fontStyle: 'bold',
          },
        },
      };
    },
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      startDateId: `datepicker-${Date.now()}-start`,
      endDateId: `datepicker-${Date.now()}-end`,
      startDatePoints: [],
      endDatePoints: [],
      indicatorFieldNames: [
        'work',
        'movementPain',
        'selfConfidence',
        'sleep',
        'energy',
        'socialLinks',
        'anxiety',
        'selfBodyImage',
      ],
    };
  },
  methods: {
    async setupDatepicker() {
      $.fn.datepicker.dates.fr = {
        days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        daysShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
        daysMin: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        monthsShort: [
          'janv.',
          'févr.',
          'mars',
          'avril',
          'mai',
          'juin',
          'juil.',
          'août',
          'sept.',
          'oct.',
          'nov.',
          'déc.',
        ],
        today: "Aujourd'hui",
        monthsTitle: 'Mois',
        clear: 'Effacer',
        weekStart: 1,
        format: 'dd/mm/yyyy',
      };
      $('.datepicker-input')
        .datepicker({
          maxViewMode: 0,
          todayBtn: 'linked',
          language: 'fr',
          autoclose: true,
        })
        .on('show', e => {
          const today
            = '<th colspan="2" class="today" style="display: table-cell;">Aujourd\'hui</th>';
          $('.datepicker').each(function addTodayBtn() {
            if (
              !$(this)
                .html()
                .includes(today)
            ) {
              $('.datepicker-switch')
                .parent()
                .append(today);
            }
          });
          if (
            $('.datepicker')
              .attr('style')
              .indexOf('left: -') >= 0
          ) {
            const style = $('.datepicker').attr('style');
            $('.datepicker').attr('style', style.replace(/(left: -)(\d+)(px;)/gi, 'left: 0;'));
          }
        })
        .on('changeDate', () => {
          const startDate = $(`#${this.startDateId}`)
            .data('datepicker')
            .getFormattedDate('yyyy-mm-dd');
          if (this.startDate !== startDate) {
            this.startDate = startDate;
            this.getIndicatorData('startDate');
            this.$store.commit('lastSelectedIndicatorStartDate', startDate);
          }

          const endDate = $(`#${this.endDateId}`)
            .data('datepicker')
            .getFormattedDate('yyyy-mm-dd');
          if (this.endDate !== endDate) {
            this.endDate = endDate;
            this.getIndicatorData('endDate');
          }
        });

      this.setInitialPickerValues();
    },

    async setInitialPickerValues(ignoreStartDateParam) {
      if (!ignoreStartDateParam) {
        const res = await this.$http.get(
          `/api/indicator?filters[userId]=${this.$store.state.user.id}&sort[id]=DESC`,
        );
        let indexOfLastRecord = 0;
        if (res
          && res.data
          && res.data.body
          && res.data.body[0]
          && res.data.body[0].date
          && res.data.body[0].date === moment(new Date()).format('YYYY-MM-DD')
          && res.data.body.length > 1) {
          indexOfLastRecord = 1;
        }
        const lastSavedRecord = res && res.data && res.data.body && res.data.body[indexOfLastRecord];
        const initialStartDate = lastSavedRecord && lastSavedRecord.date;

        if (initialStartDate) {
          const newStartDate = moment(initialStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
          this.startDate = initialStartDate;

          const startDatePicker = $(`#${this.startDateId}`);
          startDatePicker.data({ date: newStartDate });
          startDatePicker.datepicker('update');
          startDatePicker.datepicker().val(newStartDate);
          this.getIndicatorData('startDate');
        }
      }

      this.endDate = moment().format('YYYY-MM-DD');
      const newEndDate = moment(this.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
      const endDatePicker = $(`#${this.endDateId}`);
      endDatePicker.data({ date: newEndDate });
      endDatePicker.datepicker('update');
      endDatePicker.datepicker().val(newEndDate);
      this.getIndicatorData('endDate');
    },

    async getIndicatorData(dateType) {
      if (['startDate', 'endDate'].indexOf(dateType) === -1) {
        return;
      }

      const dateValue = this[dateType];

      if (!dateValue) {
        return;
      }
      const userId = this.userId ? `${this.userId}` : this.$store.state.user.id;
      this.$http
        .get(
          `/api/indicator?filters[date]=${dateValue}&sort[id]=DESC&filters[userId]=${userId}&options[searchMode]=exact`,
        )
        .then(res => {
          const record = res && res.data && res.data.body && res.data.body[0];

          if (!record) {
            if (dateType === 'startDate') {
              this.startDatePoints = [];
            } else {
              this.endDatePoints = [];
            }
            return;
          }

          const points = [];

          this.indicatorFieldNames.forEach(name => {
            points.push(record[name] || 0);
          });

          if (dateType === 'startDate') {
            this.startDatePoints = points;
          } else {
            this.endDatePoints = points;
          }
        })
        .catch(this.apiErrorCallback);
    },
  },
  mounted() {
    this.setupDatepicker();
  },
  beforeDestroy() {
    $('.c-chart-radar').css('visibility', 'hidden');
  },
};
</script>

<template>
  <div class="favorite-icon"
       :class="{isFavorite}"
       @click="switchFavorite()">
    <CIcon :name="isFavorite ? 'favoriteTrue' : 'favoriteFalse'" v-if="!isRequestOngoing"/>
    <span v-if="isRequestOngoing"
          class="spinner-border spinner-border-sm text-white"
          role="status"
          aria-hidden="true">
        </span>
  </div>
</template>

<script>
export default {
  name: 'MediaFavoriteIcon',
  props: ['mediaId'],
  data() {
    return {
      isRequestOngoing: false
    };
  },
  methods: {
    switchFavorite() {
      if (this.isRequestOngoing) {
        return;
      }
      this.isRequestOngoing = true;
      if (this.isFavorite) {
        this.$http.delete(`/api/media/favorite/${this.mediaId}`)
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit('favorites', response.data.body);
            }
            this.isRequestOngoing = false;
          })
          .catch(err => {
            this.isRequestOngoing = false;
            this.$notify({
              title: 'Une erreur s\'est produite veuillez rafraichir la page',
              type: 'warning',
            });
          });
      } else {
        this.$http.post('/api/media/favorite/', { mediaId: this.mediaId })
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit('favorites', response.data.body);
            }
            this.isRequestOngoing = false;
          })
          .catch(err => {
            this.isRequestOngoing = false;
            this.$notify({
              title: 'Une erreur s\'est produite veuillez rafraichir la page',
              type: 'warning',
            });
          });
      }
    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favorites.includes(this.mediaId);
    }
  },
};
</script>

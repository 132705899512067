<template>
  <div class="page-help">
    <div class="row">
      <div class="col-sm-6">
        <h1>Nous contacter</h1>
        <p>Nous prenons au sérieux l'engagement envers nos utilisateurs.</p>
        <p>
          Si vous avez besoin de notre aide avec votre compte utilisateur, avez des questions sur
          l'utilisation de la plateforme ou rencontrez des difficultés techniques, n'hésitez pas à
          nous contacter.
        </p>
        <h5 class="py-1"><CIcon name="cilPaperPlane" /> CONTACT@DOCLIVI.COM</h5>
        <p>
          Si vous ne trouvez pas la réponse à votre question, n'hésitez pas à contacter
          l'assistance.
        </p>
      </div>
      <div class="col-sm-6">
        <CForm>
          <CInput
            type="text"
            v-model="name"
            label="Nom et Prénom"
            :addInputClasses="{
              'is-valid': isFormSubmitted && name !== '',
              'is-invalid': isFormSubmitted && name === '',
            }"
          />
          <CInput
            type="email"
            v-model="email"
            label="Adresse e-mail"
            :addInputClasses="{
              'is-valid': isEmailValid(),
              'is-invalid': isFormSubmitted && !isEmailValid(),
            }"
          />
          <CTextarea
            v-model="message"
            label="Votre message"
            :addInputClasses="{
              'is-valid': isFormSubmitted && message !== '',
              'is-invalid': isFormSubmitted && message === '',
            }"
          />
          <button
            type="submit"
            :disabled="isSubmitting || isSent"
            class="btn btn-primary btn-submit col-12 mb-2"
            :class="{
              'click-disabled': isSubmitting || isSent,
              'cursor-normal': isSubmitting || isSent,
            }"
            @click.prevent="submitForm()"
          >
            <span v-if="!isSubmitting && !isSent" class="text-white">Confirmer et enregistrer</span>
            <span
              v-if="isSubmitting"
              class="spinner-border spinner-border-sm text-white"
              role="status"
              aria-hidden="true"
            >
            </span>
            <span v-if="!isSubmitting && isSent" class="text-white">Message envoyé !</span>
          </button>
        </CForm>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'help',
  data() {
    return {
      isSubmitting: false,
      isFormSubmitted: false,
      isSent: false,
      name: '',
      email: '',
      message: '',
    };
  },
  methods: {
    isEmailValid() {
      return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.email);
    },
    submitForm() {
      this.isSubmitting = true;
      this.isFormSubmitted = true;
      if (!this.isEmailValid() || this.name === '' || this.message === '') {
        this.isSubmitting = false;
        return;
      }

      this.$http
        .post('/api/help', {
          name: this.name,
          email: this.email,
          message: this.message,
        })
        .then(res => {
          if (res.status === 200) {
            this.isSent = true;
          } else {
            this.$notify({
              title: 'Erreur',
              message: this.$t('common.messages.refreshretry'),
              type: 'danger',
              timeout: 5000,
              horizontalAlign: 'center',
              verticalAlign: 'top',
            });
          }
          this.isSubmitting = false;
        })
        .catch(err => {
          this.$notify({
            title: 'Erreur',
            message: this.$t('common.messages.refreshretry'),
            type: 'danger',
            timeout: 5000,
            horizontalAlign: 'center',
            verticalAlign: 'top',
          });
          this.isSubmitting = false;
        });
    },
  },
};
</script>

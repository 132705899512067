<template>
  <div class="card h-100 widget-report-form">
    <div class="card-header bg-white container" v-if="$mq === 'xl' || $mq === 'lg'">
      <div class="d-flex justify-content-between">
        <h5 class="text-primary font-weight-bold">
          Notez chaque composante ci-dessous sur une échelle de 0 à 10
        </h5>
        <b class="text-md-right ml-2 date">{{ moment().format('DD/MM/YYYY') }}</b>
      </div>
    </div>
    <div class="card-header bg-beige-light container border-bottom border-white" v-else>
      <h5 class="h6 text-primary font-weight-bold text-center">
        Évaluation de ma qualité de vie
      </h5>
      <div class="d-flex justify-content-between align-items-center">
        <p class="instructions mb-0">
          Notez chaque composante ci-dessous sur une échelle de 0 à 10
        </p>
        <b class="text-md-right ml-2 date">{{ moment().format('DD/MM/YYYY') }}</b>
      </div>
    </div>
    <div
      class="card-body pt-0 py-md-0"
      :class="$mq === 'xl' || $mq === 'lg' ? 'bg-white px-4' : 'bg-beige-light'"
    >
      <Ratings @onDataSaved="onDataSaved()" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Ratings from '@/components/UIComponents/Ratings.vue';

export default {
  name: 'reportForm',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Ratings,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    moment,
    onDataSaved() {
      this.$emit('onDataSaved');
    },
  },
};
</script>

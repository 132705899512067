<template>
  <div class="add-goal parent-px-0 parent-pt-0">
    <div class="back-button px-4 px-xl-5">
      <CIcon name="arrowLeft" height="24" width="24"/>
      <router-link to="/app/goals">Retour</router-link>
    </div>

    <div class="container py-4 px-2 px-md-4 p-xl-5">
      <div class="row mb-4">
        <h3 class="h3 col-12 col-md-6 font-weight-bold mb-4">J'ajoute un nouvel objectif</h3>
        <div class="col-12 col-md-6 text-md-right">
          <router-link
            to="/app/goals/add/form"
            class="add-custom btn btn-primary px-4 d-flex align-items-center d-md-inline-block"
          >
            <CIcon name="editDocument" size="xl" v-if="$mq === 'sm' || $mq === 'md'" class="mr-4"/>
            Créer mon objectif personnalisé
          </router-link>
        </div>
      </div>
      <div class="row" v-for="goals in groupedGoals" :key="goals[0].id">
        <div class="col-md-6" v-for="goal in goals" :key="goal.id">
          <div class="card d-flex flex-row align-items-center p-2">
            <div class="icon border rounded-circle d-flex align-items-center justify-content-center text-dark">
              <CIcon :name="goal.icon" height="36" width="36"/>
            </div>
            <div class="mx-3">
              <h6 class="font-weight-bold text-dark mb-1">{{ goal.title }}</h6>
              <p class="text-small m-0">À atteindre sous {{ goal.duration }}</p>
            </div>
            <button type="button"
                    class="btn btn-outline-primary ml-auto mr-2 px-3"
                    @click="$router.push({name: 'GoalForm', params: {goal: goal}})"
            >Je choisis</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'AddGoal',
  data() {
    return {
      goals: [],
    };
  },
  async mounted() {
    await this.getGoals();
  },
  methods: {
    async getGoals() {
      const { data } = await this.$http.get(
        '/api/goal?filters[isTemplate]=1&sort[startDate]=ASC'
      );
      if (data && data.body) {
        this.goals = data.body;
      }
    }
  },
  computed: {
    groupedGoals() {
      return _.chunk(this.goals, 2);
    },
  }
};
</script>

<template>
  <div class="goals parent-px-0">
    <!-- Mobile Main Nav -->
    <nav class="main-tabs bg-white w-100 d-sm-none mb-4">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a
          class="nav-item nav-link py-3 w-auto active"
          id="nav-goals-tab"
          data-toggle="tab"
          href="#nav-goals"
          role="tab"
          aria-controls="nav-goals"
          aria-selected="true"
        >
          <span class="d-inline-block text-primary">Objectifs</span>
        </a>
        <a
          class="nav-item nav-link py-3 w-auto"
          id="nav-badges-tab"
          data-toggle="tab"
          href="#nav-badges"
          role="tab"
          aria-controls="nav-badges"
          aria-selected="false"
        >
          <span class="d-inline-block text-primary">Badges</span>
        </a>
      </div>
    </nav>
    <!-- End Mobile Main Nav -->

    <div class="main-tabs-content tab-content row" id="nav-tabContent">
      <!-- Goals -->
      <div
        class="tab-pane fade show active d-sm-block col-md-7 d-sm-block"
        id="nav-goals"
        role="tabpanel"
        aria-labelledby="nav-goals-tab"
      >
        <div class="d-flex justify-content-between mb-sm-4">
          <h3 class="font-weight-bold" v-if="$mq !== 'sm'">Objectifs</h3>
          <router-link
            to="/app/goals/add"
            class="btn btn-primary px-4"
            :class="$mq === 'sm' ? 'btn-add-mobile' : ''"
          >
            <span v-if="$mq !== 'sm'" class="text-white">
              J'ajoute un objectif +
            </span>
          </router-link>
        </div>
        <!-- Goals Nav -->
        <nav class="goals-tabs bg-white mb-4">
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              class="nav-item nav-link py-3 d-flex justify-content-between active"
              id="nav-completed-tab"
              data-toggle="tab"
              href="#nav-completed"
              role="tab"
              aria-controls="nav-completed"
              aria-selected="true"
            >
              <span class="d-inline-block">Terminé</span>
              <span class="d-inline-block pr-2">{{ completedGoals.length }}</span>
            </a>
            <a
              class="nav-item nav-link py-3 d-flex justify-content-between"
              id="nav-inprogress-tab"
              data-toggle="tab"
              href="#nav-inprogress"
              role="tab"
              aria-controls="nav-inprogress"
              aria-selected="false"
            >
              <span class="d-inline-block">En cours</span>
              <span class="d-inline-block pr-2">{{ incompleteGoals.length }}</span>
            </a>
          </div>
        </nav>
        <!-- End Goals Nav -->

        <div class="tab-content">
          <div
            class="tab-pane show active"
            id="nav-completed"
            role="tabpanel"
            aria-labelledby="nav-completed-tab"
          >
            <GoalCard
              v-for="goal in completedGoals"
              :key="goal.id"
              :goal="goal"
              v-on:changed="getGoals()"
            />
          </div>
          <div
            class="tab-pane fade"
            id="nav-inprogress"
            role="tabpanel"
            aria-labelledby="nav-inprogress-tab"
          >
            <GoalCard
              v-for="goal in incompleteGoals"
              :key="goal.id"
              :goal="goal"
              v-on:changed="getGoals()"
            />
          </div>
        </div>
      </div>
      <!-- End Goals -->

      <!-- Badges -->
      <div
        class="tab-pane col-md-5 d-sm-block"
        id="nav-badges"
        role="tabpanel"
        aria-labelledby="nav-badges-tab"
      >
        <div class="badges-header d-flex">
          <h3 class="font-weight-bold" v-if="$mq !== 'sm'">Badges</h3>
          <b class="score font-weight-bold"
            >{{ this.obtainedBadgeAmount }}/{{ this.totalBadgeAmount }}</b
          >
        </div>
        <div class="card p-4 p-lg-5">
          <div class="row mb-5" v-for="badges in groupedBadges" :key="badges[0].id">
            <div
              v-for="badge in badges"
              :key="badge.id"
              class="badges-item col-4 text-center d-flex flex-column align-items-center"
              :class="{ obtained: isBadgeObtained(badge) }"
              v-tooltip:bottom.html="getTooltipHtml(badge)"
              @click="badgeDetail = badge"
            >
              <svg v-if="!badge.image" width="120" height="120">
                <circle cx="60" cy="30" r="60" fill="#F7F2EF" />
              </svg>
              <div v-if="badge.image" class="badge-image"><img :src="badge.image" /></div>
              <span class="text-small mt-1">{{ badge.name }}</span>
              <span class="badge badge-pill badge-primary font-weight-bold" v-if="badge.new"
                >New</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- End Badges -->
    </div>

    <!-- New Badge Modal -->
    <CModal
      title="Toutes nos félicitations !"
      :show.sync="popup"
      :centered="true"
      size="md"
      class="badge-modal"
      @update:show="onBadgeModalClosed"
    >
      Vous venez d’obtenir un nouveau badge. Continuez à explorer notre plateforme pour en obtenir
      d’autres.
      <div class="badge-modal-wrapper">
        <div class="d-flex flex-row flex-wrap justify-content-center">
          <div
            v-for="badge in newObtainedBadges"
            class="badges-item obtained d-flex flex-column align-items-center mt-4 mx-3"
            :key="badge.id"
          >
            <svg v-if="!badge.image" width="60" height="60">
              <circle cx="30" cy="30" r="30" fill="#8C8C8C" />
            </svg>
            <div v-if="badge.image" class="badge-image"><img :src="badge.image" /></div>
            <span class="text mt-1">{{ badge.name }}</span>
          </div>
        </div>
      </div>
    </CModal>
    <!-- End New Badge Modal -->

    <!-- Badge Detail Mobile -->
    <div class="badge-detail" :style="isDetail ? 'width: 100%;' : ''">
      <div class="back-button">
        <CIcon name="arrowLeft" height="24" width="24" />
        <a href="#" @click="badgeDetail = {}">Retour</a>
      </div>
      <div class="badge-detail__content d-flex flex-column align-items-center p-5 text-center">
        <svg v-if="!badgeDetail.image" width="160" height="160">
          <circle cx="80" cy="80" r="80" fill="#8C8C8C" />
        </svg>
        <div v-if="badgeDetail.image" class="badge-image"><img :src="badgeDetail.image" /></div>
        <span class="h4 font-weight-bold mt-4">{{ badgeDetail.name }}</span>
        <span class="mt-4">{{ badgeDetail.description }}</span>
      </div>
    </div>
    <!-- End Badge Detail Mobile -->
  </div>
</template>

<script>
import _ from 'lodash';
import GoalCard from '@/components/UIComponents/GoalCard.vue';

export default {
  name: 'Goals',
  components: { GoalCard },
  data() {
    return {
      goals: [],
      badges: [],
      popup: false,
      badgeDetail: {},
    };
  },
  async mounted() {
    await this.loadData();
  },
  computed: {
    completedGoals() {
      return this.goals.filter(goal => goal.status === 'done');
    },
    incompleteGoals() {
      return this.goals.filter(goal => goal.status !== 'done');
    },
    groupedBadges() {
      return _.chunk(this.badges, 3);
    },
    isDetail() {
      return !_.isEmpty(this.badgeDetail);
    },
    totalBadgeAmount() {
      return this.badges && this.badges.length ? this.badges.length : 0;
    },
    obtainedBadgeAmount() {
      return (
        (this.badges || []).filter(
          badge => badge && badge.Users && badge.Users[0] && badge.Users[0].UserBadge,
        ).length || 0
      );
    },
    newObtainedBadges() {
      return (this.badges || []).filter(
        badge =>
          badge
          && badge.Users
          && badge.Users[0]
          && badge.Users[0].UserBadge
          && badge.Users[0].UserBadge.isNew,
      );
    },
  },
  methods: {
    async loadData() {
      await this.getGoals();
      await this.getBadges();

      if (this.newObtainedBadges && this.newObtainedBadges.length) {
        this.popup = true;
      }
    },
    async onBadgeModalClosed() {
      const badges = this.newObtainedBadges || [];

      await this.$http.put('/api/user-badge/hide', {
        badgeIds: badges.map(badge => badge && badge.id),
      });

      await this.loadData();
    },
    async getGoals() {
      const { data } = await this.$http.get(
        `/api/goal?filters[userId]=${this.$store.state.user.id}&sort[startDate]=DESC&options[searchMode]=exact`,
      );
      if (data && data.body) {
        this.goals = data.body;
      }
    },
    async getBadges() {
      const { data } = await this.$http.get('/api/badge?sort[id]=ASC');
      if (data && data.body) {
        this.badges = data.body;
      }
    },
    getTooltipHtml(badge) {
      return `<div class="h5 font-weight-bold">${badge.name}</div><p class="mb-0">${badge.description}</p>`;
    },
    isBadgeObtained(badge) {
      return badge && badge.Users && badge.Users[0] && badge.Users[0].UserBadge;
    },
  },
};
</script>

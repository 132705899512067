var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-help"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('h1',[_vm._v("Nous contacter")]),_vm._v(" "),_c('p',[_vm._v("Nous prenons au sérieux l'engagement envers nos utilisateurs.")]),_vm._v(" "),_c('p',[_vm._v("\n        Si vous avez besoin de notre aide avec votre compte utilisateur, avez des questions sur\n        l'utilisation de la plateforme ou rencontrez des difficultés techniques, n'hésitez pas à\n        nous contacter.\n      ")]),_vm._v(" "),_c('h5',{staticClass:"py-1"},[_c('CIcon',{attrs:{"name":"cilPaperPlane"}}),_vm._v(" CONTACT@DOCLIVI.COM")],1),_vm._v(" "),_c('p',[_vm._v("\n        Si vous ne trouvez pas la réponse à votre question, n'hésitez pas à contacter\n        l'assistance.\n      ")])]),_vm._v(" "),_c('div',{staticClass:"col-sm-6"},[_c('CForm',[_c('CInput',{attrs:{"type":"text","label":"Nom et Prénom","addInputClasses":{
            'is-valid': _vm.isFormSubmitted && _vm.name !== '',
            'is-invalid': _vm.isFormSubmitted && _vm.name === '',
          }},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_vm._v(" "),_c('CInput',{attrs:{"type":"email","label":"Adresse e-mail","addInputClasses":{
            'is-valid': _vm.isEmailValid(),
            'is-invalid': _vm.isFormSubmitted && !_vm.isEmailValid(),
          }},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('CTextarea',{attrs:{"label":"Votre message","addInputClasses":{
            'is-valid': _vm.isFormSubmitted && _vm.message !== '',
            'is-invalid': _vm.isFormSubmitted && _vm.message === '',
          }},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_vm._v(" "),_c('button',{staticClass:"btn btn-primary btn-submit col-12 mb-2",class:{
            'click-disabled': _vm.isSubmitting || _vm.isSent,
            'cursor-normal': _vm.isSubmitting || _vm.isSent,
          },attrs:{"type":"submit","disabled":_vm.isSubmitting || _vm.isSent},on:{"click":function($event){$event.preventDefault();return _vm.submitForm()}}},[(!_vm.isSubmitting && !_vm.isSent)?_c('span',{staticClass:"text-white"},[_vm._v("Confirmer et enregistrer")]):_vm._e(),_vm._v(" "),(_vm.isSubmitting)?_c('span',{staticClass:"spinner-border spinner-border-sm text-white",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v(" "),(!_vm.isSubmitting && _vm.isSent)?_c('span',{staticClass:"text-white"},[_vm._v("Message envoyé !")]):_vm._e()])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
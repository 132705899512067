var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-settings tab-content"},[_c('div',{staticClass:"tab-pane fade tab-perso px-sm-5",class:_vm.selectedSettingsTab === 'perso' ? 'show active' : '',attrs:{"role":"tabpanel"}},[_c('CForm',{staticClass:"row m-0",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.updateUser.apply(null, arguments)}}},[_c('div',{staticClass:"col-12 col-md-12 row m-0 p-0"},[_c('CInput',{staticClass:"col-12 col-md-6",attrs:{"type":"text","required":"","addInputClasses":{
            'is-valid': _vm.user.firstName,
            'is-invalid': _vm.isFormSubmitted && !_vm.user.firstName,
          },"label":_vm.$t('common.field.firstName'),"placeholder":_vm.$t('common.placeholders.firstName'),"invalid-feedback":_vm.$t('common.labels.emptyField')},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}}),_vm._v(" "),_c('CInput',{staticClass:"col-12 col-md-6",attrs:{"type":"text","required":"","addInputClasses":{
            'is-valid': _vm.user.lastName,
            'is-invalid': _vm.isFormSubmitted && !_vm.user.lastName,
          },"label":_vm.$t('common.field.lastName'),"placeholder":_vm.$t('common.placeholders.lastName'),"invalid-feedback":_vm.$t('common.labels.emptyField')},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}}),_vm._v(" "),_c('CInput',{staticClass:"col-12 col-md-6",attrs:{"type":"number","label":_vm.$t('common.field.weight'),"placeholder":_vm.$t('common.placeholders.weight'),"invalid-feedback":_vm.$t('common.labels.emptyField'),"append":"Kg"},model:{value:(_vm.user.weight),callback:function ($$v) {_vm.$set(_vm.user, "weight", $$v)},expression:"user.weight"}}),_vm._v(" "),_c('CInput',{staticClass:"col-12 col-md-6",attrs:{"addInputClasses":{
          'is-valid': _vm.isDateValid(_vm.user.birthdate),
          'is-invalid': !_vm.isDateValid(_vm.user.birthdate),
          },"label":_vm.$t('common.labels.dateOfBirth'),"placeholder":"DD/MM/YYYY","type":"date","data-date-format":"DD/MM/YYYY","vertical":""},model:{value:(_vm.user.birthdate),callback:function ($$v) {_vm.$set(_vm.user, "birthdate", $$v)},expression:"user.birthdate"}}),_vm._v(" "),_c('CInputRadioGroup',{staticClass:"mb-4 col-12 col-md-8",attrs:{"options":_vm.genderOptions,"checked":_vm.user.gender,"custom":true,"inline":true},on:{"update:checked":function($event){return _vm.$set(_vm.user, "gender", $event)}},model:{value:(_vm.user.gender),callback:function ($$v) {_vm.$set(_vm.user, "gender", $$v)},expression:"user.gender"}}),_vm._v(" "),_c('CInput',{staticClass:"col-12",attrs:{"type":"email","required":"","autocomplete":"email","addInputClasses":{
            'is-valid': _vm.isEmailValid(),
            'is-invalid': _vm.isFormSubmitted && !_vm.isEmailValid(),
          },"label":_vm.$t('common.field.email'),"placeholder":_vm.$t('common.placeholders.email'),"invalid-feedback":_vm.$t(_vm.user.email ? 'common.labels.enterValidEmail' : 'common.labels.emptyField')},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_vm._v(" "),_c('CInput',{staticClass:"col-12 col-md-6",attrs:{"type":"password","required":"","addInputClasses":{
            'is-valid':
              (!_vm.user.password && !_vm.user.confirmPassword) || _vm.isPasswordValid(_vm.user.password),
            'is-invalid': _vm.isFormSubmitted && _vm.user.password && !_vm.isPasswordValid(_vm.user.password),
          },"autocomplete":"current-password","label":_vm.$t('common.labels.newPassword'),"placeholder":_vm.$t('common.placeholders.password'),"invalid-feedback":_vm.$t(_vm.user.password ? 'common.labels.passwordPolicy' : 'common.labels.emptyField')},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_vm._v(" "),_c('CInput',{staticClass:"col-12 col-md-6",attrs:{"type":"password","required":"","addInputClasses":{
            'is-valid': _vm.user.password && _vm.user.password === _vm.user.confirmPassword,
            'is-invalid':
              _vm.isFormSubmitted && _vm.user.password && _vm.user.password !== _vm.user.confirmPassword,
          },"autocomplete":"current-password","label":_vm.$t('common.labels.confirmNewPassword'),"placeholder":_vm.$t('common.placeholders.password'),"invalid-feedback":_vm.$t('common.labels.passwordsDoNotMatch')},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}})],1),_vm._v(" "),_c('div',{staticClass:"col-md-9 offset-md-3 pt-3 text-center text-md-left"},[_c('CButton',{attrs:{"type":"submit","color":"success"},on:{"click":function($event){$event.preventDefault();return _vm.updateUser.apply(null, arguments)}}},[_vm._v("\n          "+_vm._s(_vm.$t('common.buttons.confirm'))+"\n        ")])],1)])],1),_vm._v(" "),_c('div',{staticClass:"tab-pane fade tab-common px-sm-5",class:_vm.selectedSettingsTab === 'common' ? 'show active' : '',attrs:{"role":"tabpanel"}},[_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"float-left clear mb-4",attrs:{"for":"inAppNotifications"}},[_vm._v("\n        "+_vm._s(_vm.$t('common.form.inAppNotifications'))+"\n      ")]),_vm._v(" "),_c('CSwitch',{staticClass:"float-right",attrs:{"id":"inAppNotifications","color":"primary","checked":_vm.user.inAppNotifications,"disabled":_vm.inAppNotificationsChanging},on:{"update:checked":[function($event){return _vm.$set(_vm.user, "inAppNotifications", $event)},_vm.updateNotifications]},model:{value:(_vm.user.inAppNotifications),callback:function ($$v) {_vm.$set(_vm.user, "inAppNotifications", $$v)},expression:"user.inAppNotifications"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    class="icon text-center font-weight-bold d-flex flex-column justify-content-center mr-3"
    :class="goal.status === 'done' ? '' : 'text-dark'"
  >
    <CIcon :name="goal.icon + 'Completed'" height="60" width="60" v-if="goal.status === 'done'"/>
    <radial-progress-bar
      :diameter="60"
      :completed-steps="Math.round(progress)"
      :total-steps="100"
      :startColor="primaryColor"
      :stopColor="primaryColor"
      innerStrokeColor="transparent"
      :strokeWidth="2"
      :innerStrokeWidth="2"
      class="mb-1"
      v-else
    >
      <CIcon :name="goal.icon" height="27" width="27"/>
    </radial-progress-bar>
    <span v-if="label === 'title'" class="text-small">{{ goal.title }}</span>
    <span v-else>{{ Math.round(progress) }}%</span>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress';
import { getStyle } from '@coreui/utils/src';

export default {
  name: 'goalIcon',
  props: {
    goal: {
      type: Object,
      required: true,
    },
    label: String
  },
  components: {
    RadialProgressBar
  },
  computed: {
    primaryColor() {
      return getStyle('--primary');
    },
    progress() {
      const goalDuration = new Date(this.goal.expectedEndDate) - new Date(this.goal.startDate);
      const timePassed = Date.now() - new Date(this.goal.startDate);
      const percent = timePassed * 100 / goalDuration;
      switch (true) {
        case (percent < 1):
          return 0;
        case (percent > 100):
          return 100;
        default:
          return percent;
      }
    },
  }
};
</script>

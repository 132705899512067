<template>
  <CDropdown
    inNav
    placement="bottom-end"
    class="notifications-dropdowns"
    @update:show="readAllDisabled()"
  >
    <template #toggler>
      <CHeaderNavItem>
        <CHeaderNavLink :class="{ hasNew: hasUnreadNotifications }">
          <CIcon :src="notificationIcon" />
        </CHeaderNavLink>
      </CHeaderNavItem>
    </template>
    <CDropdownHeader tag="div" class="text-center p-2">
      <strong>{{ $t('common.labels.notifications') }}</strong>
    </CDropdownHeader>
    <CDropdownItem v-if="notifications.length < 1">
      <i>Pas de notification</i>
    </CDropdownItem>
    <CDropdownItem
      v-for="(notification, index) in notifications"
      :key="`notification${notification.id}`"
      class="flex-column align-items-start"
      @click="onNotificationClicked(index)"
      @mouseover="read(index)"
      :class="{ disabled: !notification.isInitialAssessmentNotification && (!notification.url || notification.url === '') }"
    >
      <strong class="f-16 text-primary">
        {{ notification.title }} <CBadge v-if="!notification.isRead">New</CBadge>
      </strong>
      <p class="f-16 my-3">{{ notification.message }}</p>
      <span class="font-weight-bold f-12">Il y a {{ duration(notification.sentOn) }}</span>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import moment from 'moment';
import notificationIcon from '@/assets/icons/Notification.svg';

export default {
  name: 'HeaderNotificationsDropdown',
  data() {
    return {
      notificationIcon,
      notifications: [],
    };
  },
  methods: {
    async getNotifications() {
      const { data } = await this.$http.get(
        `/api/notification?filters[userId]=${this.$store.state.user.id}&sort[sentOn]=DESC`,
      );
      if (data && data.body) {
        const { user } = this.$store.state;

        const initialAssessmentNotification = {
          title: this.$t('common.labels.continueAssessment'),
          isNew: true,
          sentOn: user && user.createdOn,
          isInitialAssessmentNotification: true,
        };

        this.notifications = [...(this.isInitialAssessmentCompleted ? [] : [initialAssessmentNotification]), ...data.body];
      }
    },
    duration(date) {
      const duration = moment.duration(moment(new Date()).diff(date));
      if (!duration.isValid()) {
        return;
      }
      const durationInSeconds = duration.asSeconds();
      switch (true) {
        case durationInSeconds > 31104000:
          return `${Math.round(duration.asYears())} an(s)`;
        case durationInSeconds >= 2592000:
          return `${Math.round(duration.asMonths())} mois`;
        case durationInSeconds >= 86400:
          return `${Math.round(duration.asDays())} jour(s)`;
        case durationInSeconds >= 3600:
          return `${Math.round(duration.asHours())} heure(s)`;
        case durationInSeconds >= 60:
          return `${Math.round(duration.asMinutes())} minute(s)`;
        case durationInSeconds < 60:
          return `${Math.round(durationInSeconds)} secondes`;
        default:
          return `${Math.round(duration.asDays())} jour(s)`;
      }
    },
    read(index) {
      const notification = this.notifications[index];

      if (!notification.isRead && !notification.isInitialAssessmentNotification) {
        notification.isRead = true;
        this.$http.put(`/api/notification/${notification.id}`, {
          isRead: true,
        });
      }
    },
    onNotificationClicked(index) {
      const notification = this.notifications[index];
      if (!notification) {
        return;
      }

      if (notification.isInitialAssessmentNotification) {
        this.$store.dispatch('getInitialAssessmentData').then(() => {
          const { initialAssessmentQuizzes } = this.$store.state;

          if (initialAssessmentQuizzes && initialAssessmentQuizzes.length) {
            this.$router.push(`/quiz/${initialAssessmentQuizzes[0].id}`);
          }
        });
      } else {
        this.$router.push(notification.url);
      }
    },
    readAllDisabled() {
      this.notifications.forEach((notification, index) => {
        if (!notification.url || notification.url === '') {
          this.read(index);
        }
      });
    },
  },
  computed: {
    hasUnreadNotifications() {
      return this.notifications.filter(notification => !notification.isRead).length > 0;
    },
    isInitialAssessmentCompleted() {
      const { user } = this.$store.state;
      return user && user.initialAssessmentCompleted;
    },
  },
  mounted() {
    this.getNotifications();
  },
};
</script>

<template>
  <div class="full-screen">
    <div class="main-wrapper wrapper-full-page section content app-restricted-page">
      <div class="container">
        <div class="row text-center">
          <div class="col-md-12 text-center">
            <h2 class="title">{{ $t('common.labels.appRestricted') }}</h2>
            <router-link :to="{ path: '/logout' }" class="btn btn-warning mt-5">{{
              $t('common.buttons.logout')
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss">
.app-restricted-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

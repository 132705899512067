<template>
  <div>
    <div class="mb-4 px-4 px-md-0">
      <span
        class="badge mr-4 mb-4"
        v-for="dataset in defaultDatasets"
        :key="dataset.id"
        :class="selected.includes(dataset.id) ? 'active' : ''"
        :style="selected.includes(dataset.id) ? { background: dataset.borderColor } : {}"
        @click="toggleDataset(dataset.id)"
      >
        {{ dataset.label }}
      </span>
    </div>
    <CChartLine :datasets="selectedDatasets" :options="defaultOptions" style="height: 200px" />
  </div>
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs';
import { getStyle } from '@coreui/utils/src';
import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'FilteredGraph',
  components: {
    CChartLine,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    timeUnit: {
      type: String,
      default: 'week',
    },
  },
  data() {
    return {
      selected: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      records: {},
      indicatorFields: [
        {
          name: 'sleep',
          label: 'Sommeil',
          borderColor: getStyle('--rating-sleep'),
        },
        {
          name: 'socialLinks',
          label: 'Lien',
          borderColor: getStyle('--rating-socialLinks'),
        },
        {
          name: 'work',
          label: 'Travail',
          borderColor: getStyle('--rating-work'),
        },
        {
          name: 'movementPain',
          label: 'Douleurs articulaires',
          borderColor: getStyle('--rating-movementPain'),
        },
        {
          name: 'selfConfidence',
          label: 'Estime de soi',
          borderColor: getStyle('--rating-selfConfidence'),
        },
        {
          name: 'energy',
          label: 'Energie',
          borderColor: getStyle('--rating-energy'),
        },
        {
          name: 'anxiety',
          label: 'Anxiete',
          borderColor: getStyle('--rating-anxiety'),
        },
        {
          name: 'selfBodyImage',
          label: 'Image corporelle',
          borderColor: getStyle('--rating-selfBodyImage'),
        },
        {
          name: 'mood',
          label: 'Moral',
          borderColor: getStyle('--rating-mood'),
        },
      ],
    };
  },
  computed: {
    currentStartDate() {
      if (!['week', 'month', 'year'].includes(this.timeUnit)) {
        return '';
      }

      return moment()
        .startOf(this.timeUnit)
        .format('YYYY-MM-DD');
    },
    defaultDatasets() {
      const borderWidth = 2;
      const backgroundColor = 'transparent';
      const lineTension = 0;

      return this.indicatorFields.map((field, index) => ({
        id: index + 1,
        label: field.label,
        backgroundColor,
        borderWidth,
        lineTension,
        borderColor: field.borderColor,
        data: this.getRecordsByDataType(field.name),
      }));
    },
    selectedDatasets() {
      const datasets = [];
      this.defaultDatasets.forEach(dataset => {
        if (this.selected.includes(dataset.id)) {
          datasets.push(dataset);
        }
      });
      return datasets;
    },
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'day',
              },
              ticks: {
                fontFamily: getStyle('--font-family-sans-serif'),
                fontSize: 12,
                min: this.currentStartDate,
              },
              gridLines: {
                borderDash: [5, 5],
              },
            },
          ],
          yAxes: [
            {
              position: 'left',
              display: false,
              ticks: {
                max: 11,
              },
            },
          ],
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
          },
        },
        tooltips: {
          titleFontFamily: getStyle('--font-family-sans-serif'),
          bodyFontFamily: getStyle('--font-family-sans-serif'),
          bodyFontColor: getStyle('--white'),
        },
      };
    },
  },
  mounted() {
    this.getGraphData();
  },
  watch: {
    // eslint-disable-next-line
    timeUnit: function() {
      this.getGraphData();
    },
  },
  methods: {
    async getGraphData() {
      const startDate = this.currentStartDate;

      if (!startDate) {
        this.records = [];
        return;
      }

      if (!this.userId) {
        console.warn('FilteredGraph: missing userId', this.userId);
        return;
      }
      const res = await this.$http.get(
        `/api/stats/indicator?startDate=${this.currentStartDate}&endDate=${moment().format(
          'YYYY-MM-DD',
        )}&userId=${this.userId}&searchMode=exact`,
      );
      let records = (res && res.data && res.data.body) || [];
      records = _.uniqBy(_.orderBy(records, ['date', 'id'], ['asc', 'desc']), 'date');

      const data = {};

      records.forEach(record => {
        if (!record) {
          return;
        }

        this.indicatorFields.forEach(field => {
          const fieldName = field.name;

          if (!data[fieldName]) {
            data[fieldName] = [];
          }

          data[fieldName].push({
            y: parseInt(record[fieldName], 10),
            t: record.date,
          });
        });
      });

      this.records = data;
    },
    toggleDataset(id) {
      if (this.selected.includes(id)) {
        const index = this.selected.indexOf(id);
        if (index > -1) {
          this.selected.splice(index, 1);
        }
      } else {
        this.selected.push(id);
      }
    },
    getRecordsByDataType(fieldName) {
      return this.records && this.records[fieldName] ? this.records[fieldName] : [];
    },
  },
};
</script>

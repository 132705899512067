<template>
  <div class="card widget-report-radar">
    <div class="card-header bg-white">
      <h5 class="text-primary font-weight-bold">Évolution de ma qualité de vie par date</h5>
    </div>
    <div class="card-body bg-white pt-0">
      <RadarChart :user-id="userId" ref="radar" />
    </div>
  </div>
</template>

<script>
import RadarChart from '@/components/UIComponents/Charts/RadarChart.vue';

export default {
  name: 'reportRadar',
  components: {
    RadarChart,
  },
  props: {
    userId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    resetData() {
      if (this.$refs.radar) {
        this.$refs.radar.setInitialPickerValues(true);
      }
    },
  },
};
</script>

<template>
  <CDropdown inNav class="c-header-nav-items d-md-down-none" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img :src="avatar" alt="Mon profil" class="c-avatar-img" />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center p-2">
      <strong v-if="$store.state.user"
        >{{ $store.state.user.firstName || 'Menu' }} {{ $store.state.user.lastName }}
      </strong>
    </CDropdownHeader>
    <!--
    <CDropdownItem>
      <CIcon name="cil-bell" /> Updates
      <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Messages
      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task" /> Tasks
      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-comment-square" /> Comments
      <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Settings</strong>
    </CDropdownHeader>
    <CDropdownDivider />
    <CDropdownItem> <CIcon name="cil-shield-alt" /> Lock Account </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-file" /> Projects
      <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-dollar" /> Payments
      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    -->
    <!--
    <CDropdownItem @click="() => $router.push('/app/settings')">
      <CIcon class="white-to-text-primary" :src="addIcon" /> {{ $t('common.nav.avatar') }}
    </CDropdownItem>
    -->
    <CDropdownItem @click="() => $router.push('/app/settings')">
      <CIcon class="white-to-text-primary" :src="userIcon" /> {{ $t('common.nav.profile') }}
    </CDropdownItem>
    <CDropdownItem @click="() => $router.push('/app/settings')">
      <CIcon class="white-to-text-primary" :src="settingsIcon" /> {{ $t('common.nav.params') }}
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem @click="() => $router.push('/logout')">
      <CIcon class="white-to-text-primary" :src="logoutIcon" /> {{ $t('common.nav.logout') }}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import addIcon from '@/assets/icons/Add.svg';
import userIcon from '@/assets/icons/User.svg';
import settingsIcon from '@/assets/icons/SettingsSmall.svg';
import logoutIcon from '@/assets/icons/LogoutSmall.svg';
import avatar from '../../../assets/images/user-photo.png';

export default {
  name: 'HeaderAccountDropdown',
  data() {
    return {
      itemsCount: 42,
      avatar,
      addIcon,
      settingsIcon,
      logoutIcon,
      userIcon
    };
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>

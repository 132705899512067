<template>
  <div class="page-settings tab-content">
    <div
      class="tab-pane fade tab-perso px-sm-5"
      :class="selectedSettingsTab === 'perso' ? 'show active' : ''"
      role="tabpanel"
    >
      <CForm novalidate @submit.prevent="updateUser" class="row m-0">
        <!--
        <div class="col-12 col-md-3 text-center">
          <CIcon :src="avatar" class="rounded-circle avatar" width="150" height="150" />
          <input type="file" ref="avatarFile" style="display: none" @change="uploadAvatar" />
          <div class="upload mt-n3" @click="$refs.avatarFile.click()">
            <CIcon name="upload" height="24" width="24" />
          </div>
        </div>
        -->
        <div class="col-12 col-md-12 row m-0 p-0">
          <CInput
            type="text"
            class="col-12 col-md-6"
            required
            v-model="user.firstName"
            :addInputClasses="{
              'is-valid': user.firstName,
              'is-invalid': isFormSubmitted && !user.firstName,
            }"
            :label="$t('common.field.firstName')"
            :placeholder="$t('common.placeholders.firstName')"
            :invalid-feedback="$t('common.labels.emptyField')"
          />
          <CInput
            type="text"
            class="col-12 col-md-6"
            required
            :addInputClasses="{
              'is-valid': user.lastName,
              'is-invalid': isFormSubmitted && !user.lastName,
            }"
            v-model="user.lastName"
            :label="$t('common.field.lastName')"
            :placeholder="$t('common.placeholders.lastName')"
            :invalid-feedback="$t('common.labels.emptyField')"
          />
          <CInput
            type="number"
            class="col-12 col-md-6"
            v-model="user.weight"
            :label="$t('common.field.weight')"
            :placeholder="$t('common.placeholders.weight')"
            :invalid-feedback="$t('common.labels.emptyField')"
            append="Kg"
          />
          <CInput
            class="col-12 col-md-6"
            v-model="user.birthdate"
            :addInputClasses="{
            'is-valid': isDateValid(user.birthdate),
            'is-invalid': !isDateValid(user.birthdate),
            }"
            :label="$t('common.labels.dateOfBirth')"
            placeholder="DD/MM/YYYY"
            type="date"
            data-date-format="DD/MM/YYYY"
            vertical
          />
          <CInputRadioGroup
            class="mb-4 col-12 col-md-8"
            v-model="user.gender"
            :options="genderOptions"
            :checked.sync="user.gender"
            :custom="true"
            :inline="true"
          />
          <CInput
            type="email"
            required
            class="col-12"
            v-model="user.email"
            autocomplete="email"
            :addInputClasses="{
              'is-valid': isEmailValid(),
              'is-invalid': isFormSubmitted && !isEmailValid(),
            }"
            :label="$t('common.field.email')"
            :placeholder="$t('common.placeholders.email')"
            :invalid-feedback="
              $t(user.email ? 'common.labels.enterValidEmail' : 'common.labels.emptyField')
            "
          />
          <CInput
            type="password"
            required
            class="col-12 col-md-6"
            :addInputClasses="{
              'is-valid':
                (!user.password && !user.confirmPassword) || isPasswordValid(user.password),
              'is-invalid': isFormSubmitted && user.password && !isPasswordValid(user.password),
            }"
            v-model="user.password"
            autocomplete="current-password"
            :label="$t('common.labels.newPassword')"
            :placeholder="$t('common.placeholders.password')"
            :invalid-feedback="
              $t(user.password ? 'common.labels.passwordPolicy' : 'common.labels.emptyField')
            "
          />
          <CInput
            type="password"
            required
            class="col-12 col-md-6"
            :addInputClasses="{
              'is-valid': user.password && user.password === user.confirmPassword,
              'is-invalid':
                isFormSubmitted && user.password && user.password !== user.confirmPassword,
            }"
            v-model="user.confirmPassword"
            autocomplete="current-password"
            :label="$t('common.labels.confirmNewPassword')"
            :placeholder="$t('common.placeholders.password')"
            :invalid-feedback="$t('common.labels.passwordsDoNotMatch')"
          />
        </div>
        <div class="col-md-9 offset-md-3 pt-3 text-center text-md-left">
          <CButton type="submit" color="success" @click.prevent="updateUser">
            {{ $t('common.buttons.confirm') }}
          </CButton>
        </div>
      </CForm>
    </div>
    <div
      class="tab-pane fade tab-common px-sm-5"
      :class="selectedSettingsTab === 'common' ? 'show active' : ''"
      role="tabpanel"
    >
      <div class="d-block">
        <label for="inAppNotifications" class="float-left clear mb-4">
          {{ $t('common.form.inAppNotifications') }}
        </label>
        <CSwitch
          id="inAppNotifications"
          class="float-right"
          color="primary"
          v-model="user.inAppNotifications"
          :checked.sync="user.inAppNotifications"
          :disabled="inAppNotificationsChanging"
          @update:checked="updateNotifications"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import avatarIcon from '@/assets/icons/avatar.svg';

export default {
  name: 'settings',
  data() {
    return {
      avatarIcon,
      user: this.$store.state.user,
      genderOptions: [
        { label: this.$t('common.labels.male'), value: 'male' },
        { label: this.$t('common.labels.female'), value: 'female' },
      ],
      isFormSubmitted: false,
      avatarIsUploading: false,
      isRequestInProgress: false,
      inAppNotificationsChanging: false,
    };
  },
  beforeMount() {
    if (this.user.birthdate) {
      this.user.birthdate = moment(this.user.birthdate).format('YYYY-MM-DD');
    }
  },
  computed: {
    selectedSettingsTab() {
      return this.$store.state.selectedSettingsTab;
    },
    avatar() {
      return this.$store.state.user.avatarUrl || avatarIcon;
    },
  },
  methods: {
    isEmailValid() {
      return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.user.email);
    },
    isPasswordValid(value) {
      return /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(value);
    },
    isDateValid(date) {
      return /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(date) || /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/.test(date);
    },
    updateUser() {
      this.isFormSubmitted = true;

      if (
        this.isRequestInProgress
        || !this.isEmailValid()
        || (this.user.password && !this.isPasswordValid(this.user.password))
        || (this.user.password
          && this.user.confirmPassword
          && this.user.password !== this.user.confirmPassword)
      ) {
        this.isFormSubmitted = false;
        return;
      }

      const dataToUpdate = ['firstName', 'lastName', 'weight', 'birthdate', 'gender'];

      if (this.isEmailValid() && this.user.email !== this.$store.state.user.email) {
        dataToUpdate.push('email');
      }
      if (
        this.user.password
        && this.user.confirmPassword
        && this.user.password === this.user.confirmPassword
        && this.isPasswordValid(this.user.password)
      ) {
        dataToUpdate.push('password');
      }

      const userToSend = _.pick(this.user, dataToUpdate);

      if (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(userToSend.birthdate)) {
        userToSend.birthdate = moment(userToSend.birthdate, 'DD/mm/YYYY').format('YYYY-mm-DD');
      }

      this.isRequestInProgress = true;
      this.$http
        .put(`/api/user/${this.user.id}`, userToSend)
        .then(res => {
          this.$notify({
            title: 'Enregistré',
            message: this.$t('common.messages.changesSaved'),
            type: 'success',
            timeout: 5000,
            horizontalAlign: 'center',
            verticalAlign: 'top',
          });
          this.$store.dispatch('refreshUser');
        })
        .catch((err) => {
          this.isRequestInProgress = false;
          this.isFormSubmitted = false;
          this.apiErrorCallback(err);
        })
        .then(() => {
          this.isRequestInProgress = false;
          this.isFormSubmitted = false;
        });
    },
    uploadAvatar(event) {
      if (!event || !event.target || !event.target.files || this.avatarIsUploading) {
        return;
      }
      const file = event.target.files[0];
      const acceptedFormats = ['image/jpeg', 'image/png', 'image/bmp'];
      if (!acceptedFormats.includes(file.type)) {
        this.$notify({
          title: 'Format erroné',
          message: this.$t('common.messages.acceptedFormats'),
          type: 'danger',
          timeout: 5000,
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
        return;
      }

      this.avatarIsUploading = true;

      const formData = new FormData();
      formData.append('file', file);

      this.$http
        .post(`/api/user/${this.user.id}/avatar`, formData)
        .then(res => {
          this.$store.dispatch('refreshUser');
        })
        .catch(this.apiErrorCallback)
        .then(() => {
          this.avatarIsUploading = false;
        });
    },
    updateNotifications() {
      this.inAppNotificationsChanging = true;
      this.$http
        .put(`/api/user/${this.user.id}`, { inAppNotifications: this.user.inAppNotifications })
        .then(res => {
          this.$store.dispatch('refreshUser');
        })
        .catch(this.apiErrorCallback)
        .then(() => {
          this.inAppNotificationsChanging = false;
        });
    },
  },
};
</script>

<template>
  <div class="goal-form parent-px-0 parent-pt-0">
    <div class="back-button px-4 px-xl-5">
      <CIcon name="arrowLeft" height="24" width="24" />
      <router-link to="/app/goals/add">Retour</router-link>
    </div>

    <form class="container p-4 p-xl-5">
      <div class="row">
        <!-- Main Form -->
        <div class="main-form col-12 pt-3">
          <div class="form-group title d-flex">
            <!-- Choose icon -->
            <div
              class="icon-select border rounded-circle d-flex align-items-center justify-content-center mb-4 mr-3"
            >
              <CIcon :name="goal.icon" height="36" width="36" />
              <div class="upload" @click="iconList = true">
                <CIcon name="upload" height="24" width="24" />
              </div>
              <CModal :show.sync="iconList" :centered="true">
                <div class="icon-list d-flex flex-wrap">
                  <div v-for="icon in icons" :key="icon">
                    <input
                      class="d-none"
                      type="radio"
                      :id="'icon-' + icon"
                      name="icon"
                      :value="icon"
                      v-model="goal.icon"
                    />
                    <label
                      :for="'icon-' + icon"
                      class="circle border rounded-circle d-flex align-items-center justify-content-center m-2"
                    >
                      <CIcon :name="icon" height="36" width="36" class="icon" />
                    </label>
                  </div>
                </div>
                <template v-slot:footer>
                  <button class="btn btn-primary" @click.prevent="iconList = false">Choisir</button>
                </template>
              </CModal>
            </div>
            <!-- End Choose icon -->

            <textarea
              class="form-control h3 font-weight-bold text-primary w-100"
              v-model="goal.title"
              :class="{ 'is-invalid': isFormSubmitted && goal.title === '' }"
              placeholder="Cliquez ici pour écrire votre objectif"
            ></textarea>
          </div>
          <div class="input-daterange">
            <div class="form-group">
              <label for="startDate">Date de début</label>
              <div class="input-group datepicker-input-group mb-2 bg-beige-light">
                <input
                  type="text"
                  id="startDate"
                  class="form-control datepicker-input"
                  placeholder="JJ/MM/AAAA"
                  v-model="goal.startDate"
                />
                <div class="datepicker-icon">
                  <CIcon name="calendar" class="input-group-append" size="xl" />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="expectedEndDate">Date limite</label>
              <div class="input-group datepicker-input-group mb-2 bg-beige-light">
                <input
                  type="text"
                  id="expectedEndDate"
                  class="form-control datepicker-input"
                  placeholder="JJ/MM/AAAA"
                  v-model="goal.expectedEndDate"
                />
                <div class="datepicker-icon">
                  <CIcon name="calendar" class="input-group-append" size="xl" />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="duration">Durée</label>
            <input
              type="text"
              id="duration"
              class="form-control w-50 text-right font-weight-bold"
              v-model="goal.duration"
            />
          </div>
          <div class="form-group">
            <label for="notifications"
              >Je reçois des notifications pour me motiver à atteindre mon objectif</label
            >
            <label class="c-switch c-switch-pill c-switch-primary ml-2">
              <input
                type="checkbox"
                class="c-switch-input"
                id="notifications"
                v-model="goal.notifications"
                checked
              />
              <span class="c-switch-slider"></span>
            </label>
          </div>

          <button
            type="submit"
            :disabled="isSubmitting"
            class="btn btn-primary btn-submit mt-5"
            @click.prevent="submitForm()"
          >
            <span v-if="!isSubmitting" class="text-white">Confirmer et enregistrer</span>
            <span
              v-if="isSubmitting"
              class="spinner-border spinner-border-sm text-white"
              role="status"
              aria-hidden="true"
            >
            </span>
          </button>
        </div>
        <!-- End Main Form -->
      </div>
    </form>
  </div>
</template>

<script>
import 'bootstrap-datepicker';
import { getStyle } from '@coreui/utils/src';

export default {
  name: 'GoalForm',
  data() {
    return {
      isSubmitting: false,
      isFormSubmitted: false,
      goal: {
        title: '',
        icon: 'care',
        startDate: '',
        expectedEndDate: '',
        duration: 'sous 1 mois',
        notifications: true,
      },
      iconList: false,
      icons: ['scale', 'bed', 'care', 'medication', 'dumbell', 'mountain', 'restaurant'],
    };
  },
  computed: {
    primaryColor() {
      return getStyle('--primary');
    },
  },
  methods: {
    setupDatepicker() {
      const input = $('.datepicker-input');
      $.fn.datepicker.dates.fr = {
        days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        daysShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
        daysMin: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        monthsShort: [
          'janv.',
          'févr.',
          'mars',
          'avril',
          'mai',
          'juin',
          'juil.',
          'août',
          'sept.',
          'oct.',
          'nov.',
          'déc.',
        ],
        today: "Aujourd'hui",
        monthsTitle: 'Mois',
        clear: 'Effacer',
        weekStart: 1,
        format: 'dd/mm/yyyy',
      };
      $('.input-daterange')
        .datepicker({
          maxViewMode: 0,
          language: 'fr',
          inputs: input,
          autoclose: true,
        })
        .on('show', e => {
          if (
            $('.datepicker')
              .attr('style')
              .indexOf('left: -') >= 0
          ) {
            const style = $('.datepicker').attr('style');
            $('.datepicker').attr('style', style.replace(/(left: -)(\d+)(px;)/gi, 'left: 0;'));
          }
        })
        .on('change', e => {
          this.goal[e.target.id] = e.target.value;
        });
    },
    submitForm() {
      this.isSubmitting = true;
      this.isFormSubmitted = true;
      if (this.goal.title === '') {
        this.isSubmitting = false;
        return;
      }
      const goalToSend = this.goal;
      goalToSend.userId = this.$store.state.user.id;
      goalToSend.startDate = goalToSend.startDate
        .split('/')
        .reverse()
        .join('-');
      goalToSend.expectedEndDate = goalToSend.expectedEndDate
        .split('/')
        .reverse()
        .join('-');
      if (this.goal.id && this.goal.id !== '') {
        this.$http
          .put(`/api/goal/${this.goal.id}`, this.goal)
          .then(response => {
            if (response.status === 200) {
              this.$router.push('/app/goals');
            }
            this.isSubmitting = false;
          })
          .catch(err => {
            this.isSubmitting = false;
            this.$notify({
              title: "Une erreur s'est produite",
              type: 'warning',
            });
          });
      } else {
        this.$http
          .post('/api/goal/', this.goal)
          .then(response => {
            if (response.status === 200) {
              this.$router.push('/app/goals');
            }
            this.isSubmitting = false;
          })
          .catch(err => {
            this.isSubmitting = false;
            this.$notify({
              title: "Une erreur s'est produite",
              type: 'warning',
            });
          });
      }
    },
  },
  mounted() {
    this.setupDatepicker();
    if (this.$route.params.goal) {
      this.goal = (({
        title, icon, startDate, expectedEndDate, duration, notifications
      }) => ({
        title,
        icon,
        startDate,
        expectedEndDate,
        duration,
        notifications,
      }))(this.$route.params.goal);
      if (this.$route.params.edit) {
        this.goal.id = this.$route.params.goal.id;
      }
    }
  },
};
</script>

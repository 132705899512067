<template>
  <div class="page-reports">
    <h2 v-if="isAdminMode" class="mb-4">
      Patient: <span>{{ userToDisplay.firstName }} {{ userToDisplay.lastName }}</span>
    </h2>
    <div class="row">
      <div class="col-12 col-md-6 col-xl-7 order-md-12 mb-4 widget-report-form" v-if="!isAdminMode">
        <ReportFormWidget :disabled="isAdminMode" @onDataSaved="onDataSaved()" />
      </div>
      <div
        class="col-12 col-md-6 col-xl-5 mb-4"
        :class="{
          'offset-md-3': isAdminMode,
          'offset-xl-3': isAdminMode,
        }"
      >
        <ReportRadarWidget
          v-if="userToDisplay && userToDisplay.id"
          :user-id="userToDisplay && userToDisplay.id"
          ref="radar"
        />
      </div>
    </div>

    <div class="card pt-1 filtered-graph">
      <div class="card-header bg-white pb-4 pb-md-0 mb-4 mb-md-0">
        <div class="d-md-flex justify-content-between">
          <h5 class="text-primary font-weight-bold mb-3 mb-md-0">
            Évolution de ma qualité de vie par indicateurs.<br>Cliquez sur chaque indicateur pour
            découvrir mon évolution
          </h5>
          <div class="form-group">
            <div class="select-box float-left float-md-right">
              <div class="select-box__current" tabindex="1">
                <div class="select-box__value">
                  <input
                    v-model="timeUnit"
                    class="select-box__input"
                    id="time-unit-week"
                    type="radio"
                    value="week"
                    checked="checked"
                  />
                  <p class="select-box__input-text">Voir: Cette semaine</p>
                </div>
                <div class="select-box__value">
                  <input
                    v-model="timeUnit"
                    class="select-box__input"
                    id="time-unit-month"
                    type="radio"
                    value="month"
                  />
                  <p class="select-box__input-text">Voir: Ce mois</p>
                </div>
                <div class="select-box__value">
                  <input
                    v-model="timeUnit"
                    class="select-box__input"
                    id="time-unit-year"
                    type="radio"
                    value="year"
                  />
                  <p class="select-box__input-text">Voir: Cette année</p>
                </div>
                <CIcon name="arrowUp" class="select-box__icon" width="24" height="24" />
              </div>
              <ul class="select-box__list">
                <li>
                  <label
                    class="select-box__option"
                    aria-hidden="aria-hidden"
                    @click="setTimeUnit('week')"
                    >Cette semaine</label
                  >
                </li>
                <li>
                  <label
                    class="select-box__option"
                    aria-hidden="aria-hidden"
                    @click="setTimeUnit('month')"
                    >Ce mois</label
                  >
                </li>
                <li>
                  <label
                    class="select-box__option"
                    aria-hidden="aria-hidden"
                    @click="setTimeUnit('year')"
                    >Cette année</label
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body bg-white pt-0 px-0 px-md-4">
        <FilteredGraph
          ref="filteredGraph"
          v-if="userToDisplay && userToDisplay.id"
          :time-unit="timeUnit"
          :user-id="userToDisplay.id"
        />
      </div>
    </div>

    <!--<CCard>
      <CCardBody>
        <CRow>
          <CCol sm="5">
            <h5 id="traffic" class="card-title mb-0 text-primary text-bold text-nowrap">
              <strong>Evaluation de ma qualité de vie par composante</strong>
            </h5>
          </CCol>
          <CCol sm="7" class="d-none d-md-block">
            <CButtonGroup class="float-right mr-3">
              <button
                color="default"
                variant="outline"
                v-for="(value, key) in ['Day']"
                :key="key"
                class="mx-0 btn btn-outline-primary"
                @click="selected = value"
              >
                Montrer: Cette semaine
              </button>
            </CButtonGroup>
          </CCol>
        </CRow>
        <FilteredGraph style="height:300px;margin-top:40px;" />
      </CCardBody>
    </CCard>-->
  </div>
</template>

<script>
import FilteredGraph from '@/components/UIComponents/Charts/FilteredGraph.vue';
import ReportRadarWidget from '@/components/widgets/ReportRadar.vue';
import ReportFormWidget from '@/components/widgets/ReportForm.vue';

export default {
  name: 'Reports',
  components: {
    FilteredGraph,
    ReportRadarWidget,
    ReportFormWidget,
  },
  data() {
    return {
      timeUnit: 'week',
      userToDisplay: null,
      routeUserId: null,
    };
  },
  async mounted() {
    const { userId } = this.$route.query;
    this.routeUserId = userId;
    if (userId && this.userIsAdmin) {
      const { data } = await this.$http.get(`/api/user/${userId}`);
      if (data && data.body) {
        this.userToDisplay = data.body;
      }
    } else {
      this.userToDisplay = this.$store.state.user;
    }

    $('.select-box__current').on('focus', () => {
      this.isTimeUnitInputFocused = true;
    });

    $('.select-box__current').on('blur', () => {
      setTimeout(() => {
        this.isTimeUnitInputFocused = false;
      }, 200);
    });
  },
  computed: {
    userIsAdmin() {
      return (
        this.$store.state.user
        && this.$store.state.user.roles
        && this.$store.state.user.roles.indexOf('ADMIN') > -1
      );
    },
    isAdminMode() {
      return (
        this.userIsAdmin
        && this.routeUserId
        && this.userToDisplay
        && this.userToDisplay.id
        && this.$store.state.user.id !== this.userToDisplay.id
      );
    },
  },
  methods: {
    onDataSaved() {
      if (this.$refs.radar) {
        this.$refs.radar.resetData();
        this.$refs.filteredGraph.getGraphData();
      }
    },
    setTimeUnit(unit) {
      if (this.isTimeUnitInputFocused) {
        this.timeUnit = unit;
      }
    },
  },
};
</script>

<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://www.doclivi.fr" target="_blank">Doclivi</a>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
    <div class="mfs-auto"></div>
  </CFooter>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

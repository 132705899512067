<template>
  <CCard class="p-0 card-objectives-info" :class="{ 'no-data': isEmpty }">
    <CCardHeader>
      <span class="f-18">Mes objectifs & badges</span>
      <a @click="() => $router.push('/app/goals')" class="pr-1 font-weight-light float-right">
        Voir + <span class="align-middle text-primary">→</span>
      </a>
    </CCardHeader>
    <CCardBody v-if="isEmpty" class="row d-block d-md-flex">
      <p class="px-4 col-sm-9 col-md-6">
        Vous n'avez pas encore reçu de badges.
        <br />
        <br />N’attendez plus et découvrez nos contenus pour débloquer de nouveaux badges !
      </p>
      <p class="p-4 pt-md-0 mb-md-0 col-9 col-md-6">
        Vous n'avez pas encore défini d'objectifs.
        <br />
        <br />RDV dans la section “Objectifs” pour mettre en place et suivre vos objectifs !
      </p>
      <button class="btn btn-link px-4 py-4 py-md-0 my-4 my-md-0 col-md-6 offset-md-6 text-left">
        <u @click="() => $router.push('/app/goals')">Je définis mes objectifs</u>
      </button>
    </CCardBody>
    <CCardBody v-if="!isEmpty" class="row">
      <div class="col-12 col-md-4 p-md-0 mb-3 mb-md-0 b-b-1 b-md-0">
        <div class="row">
          <div
            v-for="(badge, index) in badges"
            class="badges-item obtained m-0 col-4 col-md-6 text-center p-md-0"
            :class="{ 'd-sm-down-none': index > 2 }"
            :key="badge.id"
          >
            <svg v-if="!badge.image" width="60" height="60">
              <circle cx="30" cy="30" r="30" fill="#8C8C8C" />
            </svg>
            <div v-if="badge.image" class="badge-image"><img :src="badge.image" /></div>
            <span class="text-small mt-1">{{ badge.name }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="goals-section">
          <div class="col-12 mb-3 mb-md-0 b-b-1 ml-md-2">
            <div class="row">
              <div
                class="col-4 col-md-3"
                v-for="(goal, index) in completedGoals"
                :key="goal.id"
                :class="{ 'd-sm-down-none': index > 2 }"
              >
                <GoalIcon :goal="goal" label="title" />
              </div>
              <div v-if="completedGoals < 1" class="text-center col-12 pt-3">
                <i
                  >Vous n’avez pas rempli d’objectifs,<br />commencez dès maintenant pour booster
                  votre motivation :)
                </i>
              </div>
            </div>
          </div>
          <div class="col-12 pt-md-2 ml-md-2">
            <div class="row">
              <div
                class="col-4 col-md-3"
                v-for="goal in incompleteGoals"
                :class="{ 'd-sm-down-none': index > 2 }"
                :key="goal.id"
              >
                <GoalIcon :goal="goal" label="title" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import GoalIcon from '@/components/UIComponents/GoalIcon.vue';

export default {
  name: 'achievements',
  components: {
    GoalIcon,
  },
  data() {
    return {
      badges: [],
      goals: [],
    };
  },
  async mounted() {
    await this.getBadges();
    await this.getGoals();
  },
  methods: {
    async getBadges() {
      const { data } = await this.$http.get('/api/badge?sort[id]=ASC');
      if (data && data.body) {
        this.badges = data.body
          .filter(badge => badge && badge.Users && badge.Users[0] && badge.Users[0].UserBadge)
          .sort(
            (a, b) =>
              new Date(b.Users[0].UserBadge.createdOn) - new Date(a.Users[0].UserBadge.createdOn),
          )
          .slice(0, 4);
      }
    },
    async getGoals() {
      const { data } = await this.$http.get(
        `/api/goal?filters[userId]=${this.$store.state.user.id}&sort[startDate]=DESC&options[searchMode]=exact`,
      );
      if (data && data.body) {
        this.goals = data.body;
      }
    },
  },
  computed: {
    completedGoals() {
      return this.goals.filter(goal => goal.status === 'done').slice(0, 4);
    },
    incompleteGoals() {
      return this.goals.filter(goal => goal.status !== 'done').slice(0, 4);
    },
    isEmpty() {
      return this.badges.length < 1 && this.goals.length < 1;
    },
  },
};
</script>
